/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { OpportunityInvolvement } from './OpportunityInvolvement';
import {
    OpportunityInvolvementFromJSON,
    OpportunityInvolvementFromJSONTyped,
    OpportunityInvolvementToJSON,
} from './OpportunityInvolvement';
import type { OpportunityLocalisationSummary } from './OpportunityLocalisationSummary';
import {
    OpportunityLocalisationSummaryFromJSON,
    OpportunityLocalisationSummaryFromJSONTyped,
    OpportunityLocalisationSummaryToJSON,
} from './OpportunityLocalisationSummary';
import type { OpportunityProspectingSummary } from './OpportunityProspectingSummary';
import {
    OpportunityProspectingSummaryFromJSON,
    OpportunityProspectingSummaryFromJSONTyped,
    OpportunityProspectingSummaryToJSON,
} from './OpportunityProspectingSummary';
import type { OpportunityQuerySummaryAllOf } from './OpportunityQuerySummaryAllOf';
import {
    OpportunityQuerySummaryAllOfFromJSON,
    OpportunityQuerySummaryAllOfFromJSONTyped,
    OpportunityQuerySummaryAllOfToJSON,
} from './OpportunityQuerySummaryAllOf';
import type { OpportunityStatus } from './OpportunityStatus';
import {
    OpportunityStatusFromJSON,
    OpportunityStatusFromJSONTyped,
    OpportunityStatusToJSON,
} from './OpportunityStatus';
import type { OpportunityStatusReason } from './OpportunityStatusReason';
import {
    OpportunityStatusReasonFromJSON,
    OpportunityStatusReasonFromJSONTyped,
    OpportunityStatusReasonToJSON,
} from './OpportunityStatusReason';
import type { OpportunitySummary } from './OpportunitySummary';
import {
    OpportunitySummaryFromJSON,
    OpportunitySummaryFromJSONTyped,
    OpportunitySummaryToJSON,
} from './OpportunitySummary';

/**
 * 
 * @export
 * @interface OpportunityQuerySummary
 */
export interface OpportunityQuerySummary {
    /**
     * 
     * @type {OpportunityStatus}
     * @memberof OpportunityQuerySummary
     */
    status: OpportunityStatus;
    /**
     * 
     * @type {OpportunityInvolvement}
     * @memberof OpportunityQuerySummary
     */
    involvement: OpportunityInvolvement;
    /**
     * 
     * @type {number}
     * @memberof OpportunityQuerySummary
     */
    weight: number;
    /**
     * 
     * @type {Date}
     * @memberof OpportunityQuerySummary
     */
    nextDueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpportunityQuerySummary
     */
    signatureDate?: Date;
    /**
     * 
     * @type {Array<OpportunityStatusReason>}
     * @memberof OpportunityQuerySummary
     */
    statusReasons?: Array<OpportunityStatusReason>;
    /**
     * 
     * @type {Metadata}
     * @memberof OpportunityQuerySummary
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof OpportunityQuerySummary
     */
    name: string;
    /**
     * 
     * @type {OpportunityLocalisationSummary}
     * @memberof OpportunityQuerySummary
     */
    localisation: OpportunityLocalisationSummary;
    /**
     * 
     * @type {OpportunityProspectingSummary}
     * @memberof OpportunityQuerySummary
     */
    prospecting?: OpportunityProspectingSummary;
    /**
     * 
     * @type {string}
     * @memberof OpportunityQuerySummary
     */
    highlight?: string;
}

/**
 * Check if a given object implements the OpportunityQuerySummary interface.
 */
export function instanceOfOpportunityQuerySummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "involvement" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "nextDueDate" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "localisation" in value;

    return isInstance;
}

export function OpportunityQuerySummaryFromJSON(json: any): OpportunityQuerySummary {
    return OpportunityQuerySummaryFromJSONTyped(json, false);
}

export function OpportunityQuerySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityQuerySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': OpportunityStatusFromJSON(json['status']),
        'involvement': OpportunityInvolvementFromJSON(json['involvement']),
        'weight': json['weight'],
        'nextDueDate': (new Date(json['nextDueDate'])),
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
        'statusReasons': !exists(json, 'statusReasons') ? undefined : ((json['statusReasons'] as Array<any>).map(OpportunityStatusReasonFromJSON)),
        'metadata': MetadataFromJSON(json['metadata']),
        'name': json['name'],
        'localisation': OpportunityLocalisationSummaryFromJSON(json['localisation']),
        'prospecting': !exists(json, 'prospecting') ? undefined : OpportunityProspectingSummaryFromJSON(json['prospecting']),
        'highlight': !exists(json, 'highlight') ? undefined : json['highlight'],
    };
}

export function OpportunityQuerySummaryToJSON(value?: OpportunityQuerySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': OpportunityStatusToJSON(value.status),
        'involvement': OpportunityInvolvementToJSON(value.involvement),
        'weight': value.weight,
        'nextDueDate': (value.nextDueDate.toISOString()),
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString()),
        'statusReasons': value.statusReasons === undefined ? undefined : ((value.statusReasons as Array<any>).map(OpportunityStatusReasonToJSON)),
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'localisation': OpportunityLocalisationSummaryToJSON(value.localisation),
        'prospecting': OpportunityProspectingSummaryToJSON(value.prospecting),
        'highlight': value.highlight,
    };
}

