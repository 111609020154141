/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityTransaction
 */
export interface OpportunityTransaction {
    /**
     * 
     * @type {number}
     * @memberof OpportunityTransaction
     */
    assetValuation?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityTransaction
     */
    balanceSheetReceived?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OpportunityTransaction
     */
    buyerShare?: number;
    /**
     * 
     * @type {Date}
     * @memberof OpportunityTransaction
     */
    offerSubmissionDeadline?: Date;
    /**
     * 
     * @type {number}
     * @memberof OpportunityTransaction
     */
    taxLatency?: number;
    /**
     * 
     * @type {string}
     * @memberof OpportunityTransaction
     */
    type?: OpportunityTransactionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OpportunityTransaction
     */
    vendorShare?: number;
    /**
     * 
     * @type {Date}
     * @memberof OpportunityTransaction
     */
    signatureDate?: Date;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityTransactionTypeEnum {
    AssetDeal = 'AssetDeal',
    ShareDeal = 'ShareDeal',
    AssetOrShareDeal = 'AssetOrShareDeal'
}


/**
 * Check if a given object implements the OpportunityTransaction interface.
 */
export function instanceOfOpportunityTransaction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityTransactionFromJSON(json: any): OpportunityTransaction {
    return OpportunityTransactionFromJSONTyped(json, false);
}

export function OpportunityTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetValuation': !exists(json, 'assetValuation') ? undefined : json['assetValuation'],
        'balanceSheetReceived': !exists(json, 'balanceSheetReceived') ? undefined : json['balanceSheetReceived'],
        'buyerShare': !exists(json, 'buyerShare') ? undefined : json['buyerShare'],
        'offerSubmissionDeadline': !exists(json, 'offerSubmissionDeadline') ? undefined : (new Date(json['offerSubmissionDeadline'])),
        'taxLatency': !exists(json, 'taxLatency') ? undefined : json['taxLatency'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'vendorShare': !exists(json, 'vendorShare') ? undefined : json['vendorShare'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
    };
}

export function OpportunityTransactionToJSON(value?: OpportunityTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetValuation': value.assetValuation,
        'balanceSheetReceived': value.balanceSheetReceived,
        'buyerShare': value.buyerShare,
        'offerSubmissionDeadline': value.offerSubmissionDeadline === undefined ? undefined : (value.offerSubmissionDeadline.toISOString().substr(0,10)),
        'taxLatency': value.taxLatency,
        'type': value.type,
        'vendorShare': value.vendorShare,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString().substr(0,10)),
    };
}

